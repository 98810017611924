<template>
  <div>
    <nav-bar></nav-bar>
    <tool-box></tool-box>

    <div class="container mt-2 mb-2">
      <!-- 试卷信息 -->
      <div class="paper">
        <div class="paper-name">{{ currentPaper.title }}</div>
        <div class="paper-info">
          <div>类型：{{ toSwitchPaperType(currentPaper.examtype_id) }}</div>
          <div>总分：{{ currentPaper.total_score }}分</div>
          <div>时间：{{ currentPaper.time }}分钟</div>
          <div>题量：{{ currentPaper.question_num }}道</div>
          <div>得分：{{ currentPaper.score }}分</div>
          <div>正确：{{ currentPaper.rightNum }}道</div>
          <div>错误：{{ currentPaper.wrongNum }}道</div>
          <div>未做：{{ currentPaper.emptyNum }}道</div>
        </div>
      </div>
      <!-- 试题及答题卡 -->
      <el-row>
        <!-- 试题 -->
        <el-col :span="18">
          <div class="question">
            <div class="question-category" v-for="item in testList" :key="item.type">
              <!-- 类别名 -->
              <div class="category-name">{{ item.title_desc }}</div>
              <!-- 类别下的试题 -->
              <div class="category-question" v-if="item.sub && item.sub.length">
                <div class="category-question-item" v-for="(test, index) in item.sub" :key="test.id" :id="test.id">
                  <!-- 题目 -->
                  <!-- 综合体需要大写数字 -->
                  <div class="item-title">
                    <div>{{ toSwitchNumberUpperCase(index + 1) }}、</div>
                    <div v-html="test.title"></div>
                  </div>

                  <!-- 综合体 -->
                  <div>
                    <div class="type-five-item" v-for="(innerTest, innerTestIndex) in test.testList" :key="innerTest.id" :id="innerTest.id">
                      <!-- 题目 -->
                      <div class="item-title">
                        <div>{{ innerTestIndex + 1 }}、</div><div v-html="innerTest.title"></div>
                      </div>
                      <!-- 选项 -->
                      <!-- 判断 -->
                      <div class="item-option" v-if="innerTest.type == 3">
                        <div class="item-option-item"><span>A.</span><div>对</div></div>
                        <div class="item-option-item"><span>B.</span><div>错</div></div>
                      </div>
                      <!-- 非判断 -->
                      <div class="item-option" v-else>
                        <div class="item-option-item" v-for="option in innerTest.optionList" :key="option.label">
                          <span>{{ option.label }}.</span>
                          <div v-html="option.value"></div>
                        </div>
                      </div>
                      <!-- 试题解析 -->
                      <div class="item-analysis">
                        <div class="item-analysis-choose">
                          <div class="label">您的答案：</div>
                          <div class="value" v-html="innerTest.user_answer"></div>
                        </div>
                        <div class="item-analysis-answer">
                          <div class="label">正确答案：</div>
                          <div class="value" v-html="innerTest.answer"></div>
                        </div>
                        <div class="item-analysis-info">
                          <div class="label">试题解析：</div>
                          <div class="value" v-html="innerTest.analysis"></div>
                        </div>
                      </div>
                      <!-- 题脚 -->
                      <div class="item-footer">
                        <el-tooltip v-if="innerTest.fav == 0" effect="dark" content="收藏" placement="top">
                          <el-button type="primary" circle size="small" icon="el-icon-star-off"
                            @click="doCollectTest(innerTest, item.id)"></el-button>
                        </el-tooltip>
                        <el-tooltip v-else effect="dark" content="取消收藏" placement="top">
                          <el-button type="warning" circle size="small" icon="el-icon-star-on"
                            @click="doCancleCollectTest(innerTest, item.id)"></el-button>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
			  
              <div class="category-question" v-else>
                <div class="category-question-item" v-for="(test, index) in item.test" :key="test.id" :id="test.id">
                  <!-- 题目 -->
                  <!-- 综合体需要大写数字 -->
                  <div class="item-title" v-if="item.type == 5">
                    <div>{{ toSwitchNumberUpperCase(index + 1) }}、</div>
                    <div v-html="test.title"></div>
                  </div>
                  <!-- 非综合体 -->
                  <div class="item-title" v-else>
                    <div>{{ index + 1 }}、</div><div v-html="test.title"></div>
                  </div>
                  <!-- 选项 -->
                  <!-- 单选 -->
                  <div class="item-option" v-if="item.type == 3">
                    <div class="item-option-item"><span>A.</span><div>对</div></div>
                    <div class="item-option-item"><span>B.</span><div>错</div></div>
                  </div>
                  <!-- 综合体 -->
                  <div v-if="item.type == 5">
                    <div class="type-five-item" v-for="(innerTest, innerTestIndex) in test.testList" :key="innerTest.id" :id="innerTest.id">
                      <!-- 题目 -->
                      <div class="item-title">
                        <div>{{ innerTestIndex + 1 }}、</div><div v-html="innerTest.title"></div>
                      </div>
                      <!-- 选项 -->
                      <!-- 单选 -->
                      <div class="item-option" v-if="innerTest.type == 3">
                        <div class="item-option-item"><span>A.</span><div>对</div></div>
                        <div class="item-option-item"><span>B.</span><div>错</div></div>
                      </div>
                      <!-- 非单选 -->
                      <div class="item-option" v-else>
                        <div class="item-option-item" v-for="option in innerTest.optionList" :key="option.label">
                          <span>{{ option.label }}.</span>
                          <div v-html="option.value"></div>
                        </div>
                      </div>
                      <!-- 试题解析 -->
                      <div class="item-analysis">
                        <div class="item-analysis-choose">
                          <div class="label">您的答案：</div>
                          <div class="value" v-html="innerTest.user_answer"></div>
                        </div>
                        <div class="item-analysis-answer">
                          <div class="label">正确答案：</div>
                          <div class="value" v-html="innerTest.answer"></div>
                        </div>
                        <div class="item-analysis-info">
                          <div class="label">试题解析：</div>
                          <div class="value" v-html="innerTest.analysis"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 非单选和综合 -->
                  <div class="item-option" v-else>
                    <div class="item-option-item" v-for="option in test.optionList" :key="option.label">
                      <span>{{ option.label }}.</span>
                      <div v-html="option.value"></div>
                    </div>
                  </div>
                  <!-- 题脚 -->
                  <div class="item-footer">
                    <el-tooltip v-if="test.fav == 0" effect="dark" content="收藏" placement="top">
                      <el-button type="primary" circle size="small" icon="el-icon-star-off"
                        @click="doCollectTest(test, item.id)"></el-button>
                    </el-tooltip>
                    <el-tooltip v-else effect="dark" content="取消收藏" placement="top">
                      <el-button type="warning" circle size="small" icon="el-icon-star-on"
                        @click="doCancleCollectTest(test, item.id)"></el-button>
                    </el-tooltip>
                  </div>
                  <!-- 试题解析 -->
                  <div class="item-analysis" v-if="item.type != 5">
                    <div class="item-analysis-choose">
                      <div class="label">您的答案：</div>
                      <div class="value" v-html="test.user_answer"></div>
                    </div>
                    <div class="item-analysis-answer">
                      <div class="label">正确答案：</div>
                      <div class="value" v-html="test.answer"></div>
                    </div>
                    <div class="item-analysis-info">
                      <div class="label">试题解析：</div>
                      <div class="value" v-html="test.analysis"></div>
                    </div>
                  </div>
                </div>
              </div>
			  
            </div>
          </div>
        </el-col>

        <!-- 答题卡 -->
        <el-col :span="6">
          <el-affix :offset="20">
            <div class="question-card">
              <div class="question-card-header">
                <div class="card-header-title">答题卡</div>
              </div>
              <!-- 题号 -->
              <div class="question-card-content">
                <div class="card-content-item" v-for="(item,index) in testList" :key="index">
                    <div class="item-name" v-html="toSwitchQuestionType(item.type)"></div>
                    <!-- 综合题 -->
                    <div v-if="item.sub && item.sub.length">
                      <ul class="item-option" v-for="(citem, cindex) in item.sub" :key="cindex">
                        <li class="bigNum">
                          <h3><a :href="'#' + citem.id">{{ toSwitchNumberUpperCase(cindex + 1) }}：</a></h3>
                        </li>
                        <li v-for="(test, testIndex) in citem.testList" :key="testIndex" :class="test.user_answer == '' ? 'empty' : test.flag === '' ? '' : test.flag == 1 ? 'right' : 'wrong'">
                          <a :href="'#' + test.id">{{ testIndex + 1 }}</a>
                        </li>
                      </ul>
					            <div v-if="item.sub.length == 0">无</div>
                    </div>
                    <div v-if="item.test && item.test.length">
                      <!-- 非综合题 -->
                      <ul class="item-option">
                        <li :class="question.user_answer == '' ? 'empty' : question.flag === '' ? '' : question.flag == 1 ? 'right' : 'wrong'"
                        v-for="(question, mindex) in item.test" :key="mindex">
                        <a :href="'#' + question.id">{{ mindex + 1 }}</a>
                        </li>
                      </ul>
                      <div v-if="item.test.length == 0">无</div>
                    </div>
                  </div>
              </div>
              <div class="question-card-footer">
                <div class="empty">未做</div>
                <div class="wrong">错误</div>
                <div class="right">正确</div>
              </div>
            </div>
            <!-- 按钮 -->
            <div class="question-btn">
              <div class="question-btn-submit" @click="goQuestion">重新做题</div>
            </div>
          </el-affix>
        </el-col>
      </el-row>
    </div>

    <web-footer></web-footer>
  </div>
</template>

<script>
import NavBar from "@/components/page/NavBar"
import ToolBox from "@/components/toolbox"
import WebFooter from "@/components/page/WebFooter"
import { getPaperTest, doCollectTest, doCancleCollectTest, getWrongTest } from '@/api/exam'
import { getSessionStorage } from '@/utils/auth'

export default {
  components: {
    NavBar,
    ToolBox,
    WebFooter,
  },
  data() {
    return {
      // 当前试卷
      currentPaper: {},
      // 试卷类型，1：大纲，2：试卷
      currentPaperType: 2,
      // 试题列表
      testList: [],
      // 上次做题记录，以试题id为key，选项为value
      wrongQuestion: {},
      openType:0, //打开方式，0为默认状态，1：收藏题，2：错误题
    }
  },
  created() {
    this.currentPaper = getSessionStorage('currentPaper')
    this.openType = getSessionStorage('openType') ? getSessionStorage('openType') : 0;
    this.currentPaper.score = 0
    this.currentPaper.rightNum = 0
    this.currentPaper.wrongNum = 0
    this.currentPaper.emptyNum = 0
    this.paperType = this.currentPaper.type_id == 4 ? 1 : 2
    this.getQuestionList()
  },
  methods: {
    // 获取试题
    getQuestionList() {
      // getWrongTest({
      //   flag: 0,
      //   exam_id: this.currentPaper.id,
      // }).then(wrongTest => {
      //   if (wrongTest.code == 1) {
      //     wrongTest.data.forEach(item => {
      //       // this.wrongQuestion[item.id] = item.answer
      //       if(item.test && item.test.length){
      //         item.test.forEach(citem=>{
      //           this.wrongQuestion[citem.id] = citem.user_answer
      //         })
      //       }
      //       if(item.sub && item.sub.length){
      //         item.sub.forEach(citem=>{
      //           if(citem.test && citem.test.length){
      //             citem.test.forEach(sitem=>{
      //               this.wrongQuestion[sitem.id] = sitem.user_answer
      //             })
      //           }
      //         })
      //       }
      //     })
		  
      //     getPaperTest({exam_id: this.currentPaper.id}).then(res => {
      //       if (res.code == 1) {
      //         res.data.forEach(item => {
      //           if(item.sub && item.sub.length){
      //             item.sub.forEach(test => {
      //               test = this.doPerfectQuestion(test)
      //               test.test.forEach(sub => {
      //                 sub = this.doPerfectQuestion(sub)
      //               })
      //               test.testList = test.test
      //             })
      //           }else if(item.test && item.test.length){
      //             item.test.forEach(test => {
      //               test = this.doPerfectQuestion(test)
      //             })
      //           }
      //         })
      //         this.testList = res.data
      //       }
      //     })
      //   }
      // })

      // 2022-01-04 放弃使用getWrongTest接口
      // 开启loading
      const loading = this.$loading()
      getPaperTest({exam_id: this.currentPaper.id}).then(res => {
        if (res.code == 1) {
          res.data.forEach(item => {
            if(item.sub && item.sub.length){
              item.sub.forEach(test => {
                test = this.doPerfectQuestion(test)
                test.test.forEach(sub => {
                  sub = this.doPerfectQuestion(sub)
                })
                test.testList = test.test
              })
            }else if(item.test && item.test.length){
              item.test.forEach(test => {
                test = this.doPerfectQuestion(test)
              })
            }
          })
          this.testList = res.data;
					console.log('做题记录----',this.testList);
          // 关闭loading
          loading.close()
        }
      })
    },
    // 将试题中的字段优化
    doPerfectQuestion(test) {
      // 将试题的类型拼接到题目中
      test.title = this.toSwitchQuestionType(test.type) + test.title
      // 试题中的选项
      test.optionList = this.toIntegrationOption(test)
      // 2022-01-04 放弃使用wrongQuestion
      // 初始化选项
      // if (this.wrongQuestion.hasOwnProperty(test.id)) {
      //   test.user_answer = this.wrongQuestion[test.id]
      //   // 0错误，null未做
      //   if (this.wrongQuestion[test.id]) {
      //     test.flag = 0
      //     this.currentPaper.wrongNum++
      //   } else {
      //     test.flag = null
      //     this.currentPaper.emptyNum++
      //   }
      // } else {
      //   // test.user_answer = test.answer
      //   // 1正确
      //   test.flag = 1
      //   this.currentPaper.rightNum++
      //   this.currentPaper.score += parseFloat(test.score)
      // }
      if (test.flag == 0) {
        this.currentPaper.wrongNum++
      } else if (test.flag == 1) {
        this.currentPaper.rightNum++
        this.currentPaper.score += parseFloat(test.score)
      } else {
        this.currentPaper.emptyNum++
      }
      // 如果是判断题，也有选项，就将答案转一下
      if (test.type == 3) {
        test.answer = test.answer == 1 ? '对' : '错'
        test.user_answer = test.user_answer != null ? test.user_answer == 1 ? '对' : '错' : test.user_answer
      }
      return test
    },
    // 将题型id转成题型label
    toSwitchQuestionType(typeId) {
      let label = ''
      switch (typeId) {
        case 1:
          label = "【单选题】";
          break
        case 2:
          label = "【多选题】";
          break
        case 3:
          label = "【判断题】";
          break
        case 4:
          label = "【填空题】";
          break
        case 5:
          label = "【综合题】";
          break
        case 6:
          label = "【简答题】";
          break
      }
      return '<span>' + label + '</span>'
    },
    // 将试题中的选项整合成集合
    toIntegrationOption(test) {
      let keys = ["a", "b", "c", "d", "e", "f", "g", "h", "i"]
      let options = []
      keys.forEach((key) => {
        if (test["option_" + key] && test["option_" + key].trim()) {
          options.push({
            label: key.toUpperCase(),
            value: test["option_" + key],
          })
        }
      })
      console.log(options);
      return options;
    },
    // 根据试卷的类型id转出类型label
    toSwitchPaperType(typeId) {
      switch (typeId) {
        case 1:
          return "考点预测";
        case 2:
          return "密押试卷";
        case 3:
          return "每日一练";
        case 4:
          return "章节练习";
        case 5:
          return "往年真题";
        case 6:
          return "全真机考";
        case 7:
          return "模拟试题";
      }
    },
    // 将数字转成大写，只可以转两位数
    toSwitchNumberUpperCase(number) {
      const bigNum = ["十", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"]
      if (number <= 10) {
        return bigNum[number]
      } else if (number > 10 && number < 20) {
        return "十" + bigNum[number.toString()[1]]
      } else if (number >= 20) {
        return bigNum[number.toString()[0]] + bigNum[number.toString()[1]]
      } else {
        return null
      }
    },
    // 收藏试题
    doCollectTest(test, id) {
      doCollectTest({
        test_id: test.id,
        type: this.currentPaperType,
        exam_id: this.currentPaper.id,
      }).then(res => {
        if (res.code == 1) {
          this.testList.forEach(item => {
            if (item.id == id) {
              if (item.sub && item.sub.length) {
                item.sub.forEach(sub => {
                  sub.testList.forEach(ele => {
                    if (ele.id == test.id) {
                      ele.fav = 1
                    }
                  })
                })
              } else {
                item.test.forEach(ele => {
                  if (test.id == ele.id) {
                    ele.fav = 1;
                  }
                })
              }
            }
          })
          this.$message({
            message: "收藏成功",
            type: "success",
          })
        }
      });
    },
    // 取消收藏试题
    doCancleCollectTest(test, id) {
      doCancleCollectTest({
        test_id: test.id,
        type: this.currentPaperType,
        exam_id: this.currentPaper.id,
      }).then(res => {
        if (res.code == 1) {
          this.testList.forEach(item => {
            if (item.id == id) {
              if (item.sub && item.sub.length) {
                item.sub.forEach(sub => {
                  sub.testList.forEach(ele => {
                    if (ele.id == test.id) {
                      ele.fav = 0
                    }
                  })
                })
              } else {
                item.test.forEach(ele => {
                  if (test.id == ele.id) {
                    ele.fav = 0
                  }
                })
              }
            }
          })
          this.$message({
            message: "取消收藏成功",
            type: "success",
          })
        }
      });
    },
    // 做题
    goQuestion() {
      this.$router.push('/question')
    },

  },
}
</script>

<style scoped lang="scss">
// 试卷信息 begin
.paper {
  background-color: $background-color;
  box-shadow: var(--el-box-shadow-light);
  padding: 20px;

  .paper-name {
    width: 100%;
    font-size: 22px;
    text-align: center;
  }
  .paper-info {
    width: 50%;
    margin: 20px auto 0;
    display: flex;
    flex-wrap: wrap;

    div {
      width: 125px;
      padding: 10px;
    }
  }
}
// 试卷信息 end

// 试题列表、答题卡
.el-row {
  margin-top: 20px;
}

// 试题列表 begin
.question {
  padding: 10px;

  .question-category {
    // 类别名
    .category-name {
      font-size: 16px;
    }
    // 类别试题列表
    .category-question {
      .category-question-item {
        background-color: $background-color;
        box-shadow: var(--el-box-shadow-light);
        padding: 10px;
        margin: 15px 0;

        .item-title {
          line-height: 1.5rem;
          font-size: 16px;
          font-weight: 600;
          display: flex;
        }
        .item-option {
          color: #666;
          margin-top: 20px;
          margin-left: 10px;
          display: flex;
          flex-direction: column;

          .el-radio, .el-checkbox {
            margin-bottom: 15px;
            display: flex;
            align-items: baseline;
          }
          .item-option-item {
            font-weight: 600;
            display: flex;
            align-items: baseline;
            white-space: pre-wrap;
            word-wrap: break-word;
          }
          .item-option-item > span {
            margin-right: 5px;
          }
          .item-option-item > div {
            line-height: 1.4rem;
          }
        }
        .type-five-item {
          padding-left: 20px;
          margin-top: 20px;
        }
        // 试题footer
        .item-footer {
          padding: 10px;
          display: flex;
          justify-content: flex-end;
        }
        // 解析
        .item-analysis {
          background-color: #e8e8e8;
          padding: 10px 20px;
          margin-top: 10px;

          > div {
            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            .label {
              color: $danger-color;
              width: 80px;
            }
            .value {
              flex: 1;
            }
          }
        }
      }
    }
  }
}
// 试题列表 end

// 答题卡 begin
.question-card {
  box-shadow: var(--el-box-shadow-light);
  background-color: $background-color;
  padding: 10px;

  .question-card-header {
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #EBEEF5;

    .card-header-title {
      font-size: 16px;
      font-weight: 700;
      padding-left: 10px;
    }
  }
  .question-card-content {
    width: 100%;
    height: 350px;
    margin: 10px 0;
    overflow-y: auto;

    .card-content-item {
      margin-bottom: 10px;

      .item-name {
        color: $danger-color;
      }
      .item-option {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
      }
      .item-option > li {
        width: 25px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        border: 1px solid #ccc;
        border-radius: 3px;
        margin: 2px;
        cursor: pointer;
      }
      .item-option > li.right {
        background-color: $primary-color;
      }
      .item-option > li.wrong {
        background-color: $danger-color;
      }
      .item-option > li.bigNum {
        width: 50px;
        text-align: right;
        border: none;
      }
      .item-option > li a {
        width: 100%;
        display: inline-block;
      }
    }
  }
  .question-card-footer {
    padding: 10px 10px 0;
    display: flex;
  }
  .question-card-footer > div {
    width: 45px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    text-align: center;
    border-radius: 3px;
    margin-right: 10px;
  }
  .question-card-footer > div.empty {
    border: 1px solid #ccc;
  }
  .question-card-footer > div.wrong {
    color: #fff;
    background-color: $danger-color;
  }
  .question-card-footer > div.right {
    color: #fff;
    background-color: $primary-color;
  }
}
// 答题卡 end

// 试卷按钮 begin
.question-btn {
  margin-top: 10px;
  display: flex;

  .question-btn-submit {
    background-color: $danger-color;
  }
  .question-btn-result {
    background-color: $warning-color;
  }
}
.question-btn > div {
  color: #fff;
  flex: 1;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
}
.question-btn > div:hover {
  background-color: $theme-color;
}
// 试卷按钮 end
</style>
